<script>
export default {
  name: "PublicDonationInfo",
  components: {
    DonationInfoCard: () => import("./DonationInfoCard/DonationInfoCard"),
    CampaignInfo: () => import("./DonationInfoCard/CampaignInfo"),
  },
};
</script>

<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <!-- Left Column: Campaign Info & Mobile Donation Card -->
        <v-col cols="12" md="8">
          <!-- Mobile Donation Card (only visible on small screens) -->
          <v-sheet v-show="$vuetify.breakpoint.smAndDown" class="mobile-card">
            <donation-info-card />
          </v-sheet>

          <!-- Campaign Information (always visible) -->
          <v-sheet class="campaign-info">
            <campaign-info />
          </v-sheet>
        </v-col>

        <!-- Right Column: Donation Card for larger screens -->
        <v-col cols="12" md="4">
          <v-sheet
            v-show="$vuetify.breakpoint.mdAndUp"
            class="donation-card fixed-card"
          >
            <donation-info-card />
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<style scoped>
/* Global Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Oswald", sans-serif;
  background-color: #f9f9f9;
}

/* Container */
.v-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;
}

/* Campaign Info */
.campaign-info {
  background: #fff;
  padding: 1px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

/* Donation Card */
.donation-card {
  background: #fff;
  margin: 10px;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

/* Sticky Donation Card (Desktop) */
.fixed-card {
  position: sticky;
  top: 16px;
}

/* Mobile Donation Card */
.mobile-card {
  margin-bottom: 16px;
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .fixed-card {
    position: relative;
    top: 0;
  }
}
</style>
